@media screen and (max-width: 800px) {
  div.menu-divider {
    background: radial-gradient(black, black, black, white, white);
    width: calc(100% - 5rem); } }

@media screen and (max-width: 800px) {
  div.menu-divider {
    display: none; }
  h3.menu-heading {
    border: none;
    background-color: rgba(0, 0, 0, 0) !important; }
  p.menu-info {
    padding: 0 1rem; } }

@media screen and (max-width: 800px) {
  div.meal {
    padding: 0 1rem; } }

@media screen and (max-width: 430px) {
  h3.menu-heading {
    font-size: 40px; } }
