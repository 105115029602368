@media screen and (max-width: 1150px) {
  div.no-view {
    margin-top: 9rem;
    width: calc(100vw - 4rem);
    height: 100vh;
    background: white;
    z-index: 2;
    padding: 2rem;
    white-space: pre-line;
    display: grid !important;
    justify-items: start; } }
