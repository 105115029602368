@media screen and (max-width: 940px) {
  div.header-image {
    background-size: cover; } }

@media screen and (max-width: 940px) {
  div.header-image > p.sub-heading {
    font-size: 81px; } }

@media screen and (max-width: 680px) {
  div.header-image > p.heading {
    font-size: 100px; }
  div.header-image > p.sub-heading {
    font-size: 70px; } }

@media screen and (max-width: 680px) {
  div.header-image {
    height: 20rem; } }

@media screen and (max-width: 600px) {
  div.header-image > p.heading {
    font-size: 90px; }
  div.header-image > p.sub-heading {
    font-size: 60px;
    margin-top: 10rem; } }

@media screen and (max-width: 475px) {
  div.header-image {
    height: 15rem; }
    div.header-image > p.heading {
      font-size: 75px; }
    div.header-image > p.sub-heading {
      font-size: 45px;
      margin: 9rem auto; } }

@media screen and (max-width: 370px) {
  p.sub-heading {
    font-family: 'Lato', sans-serif !important;
    font-size: 25px !important;
    margin-top: 9rem; } }
